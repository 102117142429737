import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CardDeck from 'react-bootstrap/CardDeck'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import cardImg from '../images/download.jpg'
import { useMediaQuery } from 'react-responsive'
import Dropdown from 'react-bootstrap/Dropdown'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {Redirect} from "@reach/router"
import frompy from "../json/items.json"
import { useLocation } from "@reach/router"


const ItemCard = ({image, name, description, price, isTabletOrMobile}) => (
    <div>
        <Card style={{ width: '14rem' }} className={ isTabletOrMobile ?  "mx-auto": ""}> 
        {//TODO: images 
         //<Card.Img variant="top" src={image} height='140' width='180'/>
        }
        <Card.Body>
            <Card.Title>{name}</Card.Title>
            {/*<Card.Subtitle>{description}</Card.Subtitle>
            <Card.Subtitle>
                ${price}
            </Card.Subtitle>*/}
            {/*<Button variant="primary" onClick={handleShow}>Read more</Button>*/}
        </Card.Body>
    </Card>
    </div>
);

const BakeryPage = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //let params = "e";
    let activedept = useLocation().search.substring(useLocation().search.indexOf("=")+1);//params.get('dept')
    //iteration stuff
    const rows = frompy.filter(function(item){
      return item.Dept == activedept.toUpperCase();         
  })
    const frows = rows.reduce(function (rows, key, index) { 
      return (index % 3 == 0 ? rows.push([key]) 
        : rows[rows.length-1].push(key)) && rows;
    }, []);
    
   return(
   <Layout>
               {//<Redirect noThrow from="/404"to={"/comingsoon"}/>
}
        <SEO title={activedept} />
        <br/>
        <div style={{
      display: 'inline-block',
      height: '60 px',
      width: '100%',
      background: 'yellow',
    }}>
      <h2 style={{
        fontFamily: '',
        textAlign: 'center',
        color: 'red'
      }}>{activedept? activedept.toUpperCase() : "ALL PRODUCTS"}</h2></div>
      <br/> 
      <br/>
        {
          /*TODO: THIS
          <Row className="text-center">
          <ToggleButtonGroup type="radio" name="options" defaultValue={1} className="mx-auto">
            <ToggleButton variant="secondary" value={1}>1</ToggleButton>
            <ToggleButton variant="secondary" value={2}>2</ToggleButton>
            <ToggleButton variant="secondary" value={3}>3</ToggleButton>
            <ToggleButton variant="secondary" value={4}>4</ToggleButton>
  </ToggleButtonGroup>      
      </Row>
      */
}
      <br/>
      <CardDeck className='mx-auto'>
    {/*4 CARDS GO HERE*/}
      </CardDeck>
      <br/>
      {
      frows.map((items)=> (
        <div>
          <br/>
          <CardDeck className='mx-auto'>
       {items.map((product) => (
          <ItemCard
          name={product.Name}
          description={product.Name}
          price={product.Value}
          isTabletOrMobile={isTabletOrMobile}
          />))}
          </CardDeck>
          </div>
      ))}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Steak</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <img src={cardImg}/>
            <strong>Meat and Poultry</strong>
            <br/>
            Yes I really love steak. The flavor that it provides is unparalleled and it is a great source of protien. Not to mention it is a very comfortable food. 
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
   </Layout>

      );
};

export default BakeryPage
